import { NextSeo } from 'next-seo'
import { useRouter } from 'next/router'
import type { FunctionComponent } from 'react'

import type { PageMetaFragment } from '../../graphql/fragments/PageMetaFragment.graphql'
import type { PageMetaHookOptions } from '../../graphql/hooks/pageMeta'
import { usePageMeta } from '../../graphql/hooks/pageMeta'
import { usePersonalizations } from '../../lib/hooks/usePersonalizations'
import { useClickTracker } from '../../lib/store/tracker/ClickTracker'
import { useTrackPageView } from '../../lib/store/tracker/useTracker'
import { LocalLink } from '../../shared/util/link'
import { personalizeMeta } from '../../shared/util/personalization'

export const PageMeta: FunctionComponent<PageMetaProps> = ({
    id,
    meta,
    ...restProps
}) => {
    return meta?.title ? (
        <PageMetaResolved id={id} meta={meta} {...restProps} />
    ) : (
        <SelfFetchingPageMeta id={id} {...restProps} />
    )
}

export const SelfFetchingPageMeta: FunctionComponent<
    Omit<PageMetaProps, 'meta'>
> = ({ id, ...restProps }) => {
    const { meta } = usePageMeta({ id })

    return <PageMetaResolved id={id} meta={meta} {...restProps} />
}

/**
 * Sets the contents of the page's <title> and various <meta> tags
 */
export const PageMetaResolved: FunctionComponent<PageMetaProps> = ({
    name,
    params,
    canonical,
    ogImage: overrideOgImage,
    feature,
    meta,
}) => {
    const router = useRouter()

    const personalizations = usePersonalizations()

    const personalizedMeta = personalizeMeta(meta, personalizations)

    useTrackPageView(name, params)
    useClickTracker({ feature })

    if (!personalizedMeta) return null

    const {
        title,
        description,
        isIndexed,
        twitterCard,
        twitterCreator,
        twitterSite,
        twitterImage,
        ogTitle,
        ogDescription,
    } = personalizedMeta

    const ogImage =
        overrideOgImage?.serialize(undefined, { canonical: true }) ??
        personalizedMeta.ogImage?.source

    return (
        <NextSeo
            titleTemplate={router.pathname === '/' ? undefined : '%s | Step'}
            title={title}
            description={description}
            noindex={!isIndexed}
            nofollow={!isIndexed}
            canonical={(
                canonical ?? new LocalLink({ base: router.asPath })
            ).serialize(undefined, {
                canonical: true,
            })}
            twitter={{
                cardType: twitterCard,
                handle: twitterCreator,
                site: twitterSite,
            }}
            openGraph={{
                title: ogTitle,
                description: ogDescription,
                images: ogImage ? [{ url: ogImage }] : [],
                videos: personalizedMeta?.ogVideo?.source
                    ? [
                          {
                              url: personalizedMeta.ogVideo.source,
                              secureUrl: personalizedMeta.ogVideo.source,
                              type: personalizedMeta.ogVideo.contentType,
                              width: personalizedMeta.ogVideo.width ?? 1200,
                              height: personalizedMeta.ogVideo.height ?? 630,
                          },
                      ]
                    : [],
            }}
            additionalMetaTags={
                twitterImage?.source
                    ? [
                          {
                              name: 'twitter:image',
                              content: twitterImage.source,
                          },
                      ]
                    : []
            }
        />
    )
}

export type PageMetaProps = PageMetaHookOptions & {
    /** Name of the page for the analytics */
    name: string

    /** Additional page params*/
    params?: Record<string, any>

    /** Custom canonical of the page */
    canonical?: LocalLink

    /** Tracked feature */
    feature?: string

    /** Custom og image of the page */
    ogImage?: LocalLink

    /** Page Meta data */
    meta?: PageMetaFragment
}
